import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getAccountPermissions, resetAccountPermissions } from 'redux/features/settings/settingsSlice'
import { getAvailableDomains, resetGetAvailableDomains } from 'redux/features/user/userSlice'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isSuccess } from 'redux/toolkit/api'
import {
  MlogViewType,
  getSearch,
  resetSearchDomainId,
  resetSearchMessageDirection,
  resetSearchUserId,
  setSearchDomainId,
  setSearchFilter,
  setSearchMessageDirection,
  setSearchUserId
} from 'redux/features/mstore/mstoreSlice'
import { ActionStatus, ActionTaken, MessageDirection, Reason } from 'types/Messages'
import { useMessageLogRights } from 'components/libs/userRights/pages/useMessageLogRights'

export enum UiState {
  Error,
  Loading,
  Ready
}

export interface MessageLogPreloadLogic {
  uiState: UiState
  preloadErrors: {
    getAccountPermissionsError: string | undefined
    getAvailableDomainsError: string | undefined
    noEmailDomainError: boolean
  }
}

export const useMessageLogPreloadLogic = (): MessageLogPreloadLogic => {
  const dispatch = useAppDispatch()
  const [shouldStartPreload, setShouldStartPreload] = useState(false)
  const uiStateRef = useRef(UiState.Loading)
  const isUiStateLockedRef = useRef(false)
  const {
    isGetAccountPermissionsSuccess,
    getAccountPermissionsError,
    emergencyInboxEnabled,
    isGetAvailableDomainsSuccess,
    getAvailableDomainsError,
    userId,
    availableDomains,
    viewConfigType,
    userDomainId,
    pdDomainId,
    searchLogsAllDomainsLimit
  } = useAppSelector(_store => ({
    isGetAccountPermissionsSuccess: isSuccess(_store.settings.getAccountPermissionsApiStatus),
    getAccountPermissionsError: getErrorMessage(_store.settings.getAccountPermissionsApiStatus),
    emergencyInboxEnabled: _store.settings.accountPermissions?.emergencyInboxEnabled || 0,
    isGetAvailableDomainsSuccess: isSuccess(_store.user.api.getAvailableDomainsApiStatus),
    getAvailableDomainsError: getErrorMessage(_store.user.api.getAvailableDomainsApiStatus),
    userId: _store.auth.accessTokenObject?.userId || '',
    availableDomains: _store.user.availableDomains || [],
    viewConfigType: _store.mstore.viewConfig.type,
    userDomainId: _store.auth.accessTokenObject?.domainId,
    pdDomainId: _store.auth.accessTokenObject?.pdDomainId,
    searchLogsAllDomainsLimit: _store.settings.accountPermissions?.searchLogsAllDomainsLimit || 0
  }))
  const { canUseAdminVersionOfMessageLog } = useMessageLogRights()
  const validatedPdDomainId = useMemo(() => {
    if (pdDomainId && availableDomains.some(domainInfo => domainInfo.domainId === pdDomainId)) {
      return pdDomainId
    }

    return undefined
  }, [pdDomainId, availableDomains])

  const setDefaultSearch = useCallback(() => {
    // Default search actionTaken
    if (viewConfigType !== MlogViewType.OUTBOUND_QUARANTINE && emergencyInboxEnabled === 1) {
      dispatch(
        setSearchFilter({
          action: ActionTaken.emailContinuity,
          delivery_status: ActionStatus.spooled,
          reason: Reason.any
        })
      )
    } else if (viewConfigType === MlogViewType.OUTBOUND_QUARANTINE) {
      dispatch(setSearchFilter({ action: ActionTaken.quarantined }))
    } else {
      const defaultAction =
        canUseAdminVersionOfMessageLog || validatedPdDomainId ? ActionTaken.any : ActionTaken.quarantined
      dispatch(setSearchFilter({ action: defaultAction }))
    }

    // Default search userId
    dispatch(setSearchUserId(canUseAdminVersionOfMessageLog || validatedPdDomainId ? undefined : userId))

    // Default search domainId
    let searchDomainId: string | undefined = validatedPdDomainId || String(userDomainId)
    if (canUseAdminVersionOfMessageLog && !validatedPdDomainId) {
      searchDomainId =
        availableDomains.length === 1 || availableDomains.length > searchLogsAllDomainsLimit
          ? availableDomains[0]?.domainId
          : undefined
    }
    dispatch(setSearchDomainId(searchDomainId))

    // Default message direction
    if (viewConfigType === MlogViewType.MESSAGE_LOG) {
      dispatch(setSearchMessageDirection(MessageDirection.INBOUND))
    } else {
      dispatch(setSearchMessageDirection(MessageDirection.OUTBOUND))
    }
  }, [
    viewConfigType,
    emergencyInboxEnabled,
    dispatch,
    canUseAdminVersionOfMessageLog,
    validatedPdDomainId,
    userId,
    userDomainId,
    availableDomains,
    searchLogsAllDomainsLimit
  ])

  useEffect(() => {
    dispatch(resetAccountPermissions())
    dispatch(resetGetAvailableDomains())
    setShouldStartPreload(true)
    isUiStateLockedRef.current = false
    uiStateRef.current = UiState.Loading
    return () => {
      dispatch(resetGetAvailableDomains())
      dispatch(resetSearchUserId())
      dispatch(resetSearchDomainId())
      dispatch(resetSearchMessageDirection())
    }
  }, [dispatch])

  useEffect(() => {
    if (shouldStartPreload) {
      dispatch(getAccountPermissions())
      dispatch(getAvailableDomains())
    }
  }, [dispatch, shouldStartPreload])

  const noEmailDomainError = useMemo(() => !availableDomains.length, [availableDomains.length])

  const uiState = useMemo(() => {
    if (isUiStateLockedRef.current) {
      return uiStateRef.current
    }

    const isGetAvailableDomainsCompleted = isGetAvailableDomainsSuccess || !!getAvailableDomainsError
    const isGetAccountPermissionsCompleted = isGetAccountPermissionsSuccess || !!getAccountPermissionsError
    const isPreloadCompleted = isGetAccountPermissionsCompleted && isGetAvailableDomainsCompleted
    const hasErrors = !!getAccountPermissionsError || !!getAvailableDomainsError || noEmailDomainError

    switch (true) {
      case isPreloadCompleted && !hasErrors:
        uiStateRef.current = UiState.Ready
        isUiStateLockedRef.current = true
        break
      case isPreloadCompleted && hasErrors:
        isUiStateLockedRef.current = true
        uiStateRef.current = UiState.Error
        break
      default:
        uiStateRef.current = UiState.Loading
    }
    return uiStateRef.current
  }, [
    getAccountPermissionsError,
    getAvailableDomainsError,
    isGetAccountPermissionsSuccess,
    isGetAvailableDomainsSuccess,
    noEmailDomainError
  ])

  useEffect(() => {
    if (uiState !== UiState.Ready) {
      return
    }
    setDefaultSearch()
    dispatch(getSearch())

    // Effect should run only once, when uiState is set to UiState.Ready
    // isUiStateLockedRef = true prevents subsequent updates to uiState once Ready or Error is set
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState])

  return useMemo(
    () => ({
      uiState,
      preloadErrors: {
        getAccountPermissionsError,
        getAvailableDomainsError,
        noEmailDomainError
      }
    }),
    [getAccountPermissionsError, getAvailableDomainsError, noEmailDomainError, uiState]
  )
}
